/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';
import dompurify from 'dompurify';
import { ga4ClickEvent, nprClickEventDescription } from '../../../utils/helper';
import {
  customerFeed,
  calendarDates,
  nykaaCustomActions
} from '../../../utils/entity_mapping';
import {
  postNprConcernHandler,
  googleSearchHandler,
  resetGoogleSearchHandler
  // nprOpsFeedHandler
} from '../../../service';
import DatePicker from 'react-datepicker';
import { strings } from '../../../utils/localizedStrings';

class Issue extends Component {
  constructor() {
    super();
    this.state = {
      form: 0,
      screen: 1,
      customerFeed,
      isAltNumber: false,
      isSubmit: false,
      dateIndex: -1,
      dateMeta: [],
      preferred_date: null,
      isUserDate: false,
      isRejected: null,
      goBack: { screen: 1, form: 0, preferred_date: '' } // storing original state to go back
    };
    // this.handleClick = this.handleClick.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getHeading = this.getHeading.bind(this);
    this.renderScreen = this.renderScreen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchLandmark = this.searchLandmark.bind(this);
    this.selectedLandmark = this.selectedLandmark.bind(this);
    this.renderNPRSuccessView = this.renderNPRSuccessView.bind(this);
    this.getContentId = this.getContentId.bind(this);
  }
  // componentWillReceiveProps() {
  //   const { npr } = this.props;
  //   if (npr.ops.issue_id) {
  //     this.setState({ customerFeed: npr.customer });
  //   }
  // }

  componentDidMount() {
    const { npr, user } = this.props;
    let stateCustomerFeed = customerFeed;
    let { screen, goBack } = this.state;
    if (npr.ops.issue_id) {
      stateCustomerFeed = npr.customer;
    }
    if (!this.props.tracking.config.isRVP) {
      this.goBack();
    }
    const dateMeta = calendarDates({ subdomain: user.subdomain });
    if (npr.customer.issue_id) {
      if (npr.customer.issue_id === 2) {
        stateCustomerFeed.is_rejected = true;
      }
      screen = 4;
      goBack.screen = 4;
      goBack.preferred_date = npr.customer.preferred_date;
    }
    this.setState({
      dateMeta,
      screen,
      customerFeed: stateCustomerFeed,
      goBack
    });
  }

  handleClick(e, no) {
    let {
      form,
      isRejected,
      isAltNumber,
      customerFeed,
      preferred_date
    } = this.state;
    if (no === 3) {
      isRejected = isAltNumber = null;
      customerFeed.preferred_date = preferred_date = null;
    }
    // 99 is for go back to original screen/form
    this.setState({
      screen: no === 99 ? this.state.goBack.screen : no,
      form:
        no === 99 ? this.state.goBack.form : no === 1 || no === 2 ? 0 : form,
      isRejected,
      isAltNumber,
      customerFeed: {
        ...customerFeed,
        preferred_date:
          no === 99
            ? this.state.goBack.preferred_date
            : customerFeed.preferred_date
      },
      preferred_date
    });
    if (no === 2) {
      ga4ClickEvent({
        category: 'CaptureClick',
        action: '0',
        label: nprClickEventDescription(0),
        props: this.props
      });
      ReactGA.event({
        category: 'CaptureClick',
        action: '0',
        label: nprClickEventDescription(0)
      });
    }
    e.preventDefault();
  }

  handleRadio(e, i) {
    this.setState({ form: i });
    ga4ClickEvent({
      category: 'CaptureClick',
      action: i.toString(),
      label: nprClickEventDescription(i),
      props: this.props
    });
    ReactGA.event({
      category: 'CaptureClick',
      action: i.toString(),
      label: nprClickEventDescription(i)
    });
  }

  goBack = () => {
    const { routePrefix } = this.props.user;
    this.props.history.push(
      `${routePrefix ? `/${routePrefix}` : ''}/reverse${
        this.props.history.location.search
      }`
    );
  };

  getHeading(no) {
    const { lang } = this.props.user;
    switch (no) {
      case 7:
        return <p>{strings[lang].nprFdForm.address}</p>;
      case 6:
        return (
          <Fragment>
            <p>{strings[lang].nprFdForm.timing.a}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.timing.b}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.timing.c}</p>
          </Fragment>
        );
      default:
      case 3:
      case 5:
        return <p>{strings[lang].nprFdForm.other}</p>;
      case 1:
        return (
          <Fragment>
            <p>{strings[lang].nprFdForm.phone.a}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.phone.b}</p>
          </Fragment>
        );
      case 2:
        return <p>{strings[lang].nprFdForm.reject}</p>;
    }
  }

  renderNPRSuccessView(no) {
    const { customerFeed } = this.state;

    switch (no) {
      case 7:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(customerFeed.preferred_date).format('DD-MM-YYYY')}
            </p>
            <p className="mrg-btm-10">
              Our courier partner will attempt to pickup at the following
              address
            </p>
            <p className="mrg-btm-20">
              <strong>
                {customerFeed.address}
                <br />
                {customerFeed.drop_pincode}
                <br />
                {customerFeed.landmark}
              </strong>
            </p>
          </Fragment>
        );
      case 6:
      case 3:
      case 4:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(
                customerFeed.preferred_date ?? this.state.goBack.preferred_date
              ).format('DD-MM-YYYY')}
            </p>
          </Fragment>
        );
      case 5:
        return (
          <Fragment>
            {customerFeed.preferred_date || this.state.goBack.preferred_date ? (
              <p className="text-theme expected-date mrg-btm-15">
                Pickup scheduled for{' '}
                {customerFeed.preferred_date
                  ? moment(customerFeed.preferred_date).format('DD-MM-YYYY')
                  : this.state.goBack.preferred_date}
              </p>
            ) : null}
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(customerFeed.preferred_date).format('DD-MM-YYYY')}
            </p>
            {customerFeed.phone_number ? (
              <p className="mrg-btm-20">
                Our courier partner will attempt to contact you on +91{' '}
                <strong>{customerFeed.phone_number}</strong>
              </p>
            ) : null}
            <p className="mrg-btm-20 expected-date-label">
              We will notify you as soon as this order is out for pickup.
            </p>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            {customerFeed.is_rejected ? (
              <p className="text-theme expected-date mrg-btm-15">
                Pickup cancelled
              </p>
            ) : (
              <Fragment>
                {customerFeed.preferred_date ? (
                  <p className="text-theme expected-date mrg-btm-15">
                    Pickup scheduled for{' '}
                    {moment(customerFeed.preferred_date).format('DD-MM-YYYY')}
                  </p>
                ) : null}
              </Fragment>
            )}
          </Fragment>
        );
      default:
        break;
    }
  }

  onCalendarChange = date => {
    let { customerFeed } = this.state;
    customerFeed.preferred_date = date
      ? moment(new Date(date)).format('YYYY-MM-DD')
      : '';
    this.setState({
      customerFeed,
      preferred_date: date ? date : '',
      isUserDate: true
    });
  };

  getFields(no) {
    const { lang } = this.props.user;
    // We will not show form for buckets 3,5,8 for Nykaa brands
    const doNotShowForm =
      nykaaCustomActions.brands.includes(this.props.user.subdomain) &&
      nykaaCustomActions.doNotShowNPRFormBuckets.includes(
        this.props.npr.ops.bucket_id
      );
    switch (no) {
      case 6:
        return (
          <Fragment>
            <div className="row">
              {this.state.dateMeta.length > 0
                ? this.state.dateMeta.slice(0, 2).map((i, j) => (
                    <div
                      key={j}
                      className="inputGroup left col-md-12 col-xs-24"
                    >
                      <input
                        id={i.date}
                        data-id={i.date}
                        name="ndr-checkbox"
                        className="ndr-checkbox-days"
                        type="radio"
                        checked={
                          this.state.customerFeed.preferred_date === i.date
                            ? true
                            : false
                        }
                        onChange={e => this.dateToggle(e)}
                      />
                      <label className="pre-text" htmlFor={i.date}>{`${
                        i.dayOfWeek
                      } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                    </div>
                  ))
                : null}
              <div className="inputGroup left col-md-12 col-xs-24">
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    placeholderText={strings[lang].anyOtherDate}
                    onChange={this.onCalendarChange}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={this.state.dateMeta
                      .slice(2)
                      .map(item => new Date(item.date))}
                  />
                </span>
              </div>
            </div>
          </Fragment>
        );
      case 5:
        return (
          <Fragment>
            <div className="row">
              <p className="mrg-top-10 mrg-btm-20 left col-md-12 col-xs-24">
                {strings[lang].nprFdForm.timing.c}
              </p>
              {this.state.dateMeta.length > 0
                ? this.state.dateMeta.slice(0, 2).map((i, j) => (
                    <div
                      key={j}
                      className="inputGroup left col-md-12 col-xs-24"
                    >
                      <input
                        id={i.date}
                        data-id={i.date}
                        name="ndr-checkbox"
                        className="ndr-checkbox-days"
                        type="radio"
                        checked={
                          this.state.customerFeed.preferred_date === i.date
                            ? true
                            : false
                        }
                        onChange={e => this.dateToggle(e)}
                      />
                      <label className="pre-text" htmlFor={i.date}>{`${
                        i.dayOfWeek
                      } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                    </div>
                  ))
                : null}
              <div className="inputGroup left col-md-12 col-xs-24">
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    placeholderText={strings[lang].anyOtherDate}
                    onChange={this.onCalendarChange}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={this.state.dateMeta
                      .slice(2)
                      .map(item => new Date(item.date))}
                  />
                </span>
              </div>
            </div>
            <div className="inputGroup left">
              <label className="text">{strings[lang].comments}</label>
              <textarea
                id="comment"
                className="input-text"
                rows="16"
                cols="50"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.comment || ''}
              />
            </div>
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <div className="inputGroup left">
              <input
                id="yes"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.alternateNumber(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div className="inputGroup left">
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.alternateNumber(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="no">
                {strings[lang].no}
              </label>
            </div>
            <div
              id="phone-block"
              className={`inputGroup left ${
                this.state.isAltNumber ? 'show' : 'hide'
              }`}
            >
              <label className="text">
                {strings[lang].provideAltNo}
                {/* {customerFeed?.capture?.phone_number ? ( */}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
                {/* ) : null} */}
              </label>
              <input
                id="phone_number"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state?.customerFeed?.phone_number || ''}
                // disabled={!customerFeed?.capture?.phone_number}
              />
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].nprPreferredDate}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </label>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
          </Fragment>
        );
      case 3:
      case 4:
        return doNotShowForm && this.state.form !== 3 ? null : ( // form 3 means other issue hence we will show all forms
          <Fragment>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].nprPreferredDate}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </label>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
            <div className="inputGroup left">
              <label className="text">{strings[lang].comments}</label>
              <textarea
                id="comment"
                className="input-text"
                rows="16"
                cols="50"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.comment || ''}
              />
            </div>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <div className="inputGroup left">
              <input
                id="yes"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.askForDelivery(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div className="inputGroup left">
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.askForDelivery(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="no">
                {strings[lang].no}
              </label>
            </div>
            <div
              className={`inputGroup left ${
                this.state.isRejected === null
                  ? 'hide'
                  : this.state.isRejected
                  ? 'hide'
                  : 'show'
              }`}
            >
              <label className="text">
                {strings[lang].nprPreferredDate}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </label>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
            <div id="phone-block" className={`inputGroup left`}>
              <label className="text">{strings[lang].comments}</label>
              <textarea
                id="comment"
                className="input-text"
                rows="4"
                cols="50"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.comment || ''}
              />
            </div>
          </Fragment>
        );
      case 7:
        return (
          <Fragment>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].address}
                {/* {customerFeed?.capture?.address ? ( */}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
                {/* ) : null} */}
              </label>
              <input
                id="address"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.address || ''}
                maxLength="255"
                // disabled={!customerFeed?.capture?.address}
              />
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].pincode}
                {/* {customerFeed?.capture?.drop_pincode ? ( */}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
                {/* ) : null} */}
              </label>
              <input
                id="drop_pincode"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.drop_pincode || ''}
                maxLength="6"
                // disabled={!customerFeed?.capture?.drop_pincode}
              />
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].landmark}
                {/* {customerFeed?.capture?.landmark ? ( */}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
                {/* ) : null} */}
              </label>
              <input
                id="landmark"
                type="text"
                className="input-text"
                onChange={e => this.searchLandmark(e)}
                value={this.state.customerFeed.landmark || ''}
                maxLength="128"
                // disabled={!customerFeed?.capture?.landmark}
              />
              <div className="pos-rel">
                <ul className="suggestions pos-abs">
                  {this.props.googleSearch.list.map((item, i) => (
                    <li
                      key={i}
                      onClick={e => this.selectedLandmark(e, item.description)}
                    >
                      {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].nprPreferredDate}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </label>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
          </Fragment>
        );
      default:
    }
  }

  // Truncated content shown for Nykaa brands when form is not visible
  getContentId = id => {
    switch (id) {
      case 3:
        return '3a';
      case 8:
        return '8a';
      case 5:
        return '5a';
      default:
        return id;
    }
  };

  renderScreen(no) {
    const { user, npr } = this.props;
    const { lang } = user;
    const { screen, form } = this.state;

    const sanitizer = dompurify.sanitize;
    // We will not show form for buckets 3,5,8 for Nykaa brands
    const doNotShowForm =
      nykaaCustomActions.brands.includes(user.subdomain) &&
      nykaaCustomActions.doNotShowNPRFormBuckets.includes(npr.ops.bucket_id);
    switch (no) {
      case 1:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 noMinHeight ${
              screen === 1 ? 'show' : 'hide'
            } `}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].takeAction}{' '}
                  <span
                    className="icon-arrow_back pull-left"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.goBack(e)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    strings[lang].nprBucket(
                      doNotShowForm
                        ? this.getContentId(npr.ops.bucket_id)
                        : npr.ops.bucket_id
                    )
                  )
                }}
              />
              <form className="form basic-form mrg-top-40">
                {this.getFields(npr.ops.issue_id)}
                {doNotShowForm ? null : (
                  <div className="text-center mrg-top-20 mrg-btm-10">
                    <button className="button" onClick={this.handleSubmit}>
                      {strings[lang].submit}
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div
              className="footer text-left pos-rel"
              role="button"
              tabIndex="0"
              onClick={e => this.handleClick(e, 2)}
            >
              <a className="link">{strings[lang].youFacedAnyIssue}</a>
              <span className="icon-navigate_next pull-right" />
            </div>
          </div>
        );
      case 2:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 ${
              screen === 2 ? 'show' : 'hide'
            }`}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].pleaseTellUs}{' '}
                  <span
                    className="icon-arrow_back pull-left"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.handleClick(e, 99)} // 99 is for go back
                  />
                </p>
              </div>
            </div>
            <div className="content">
              <p className="fs-14">
                <em>{strings[lang].pressNextBtn}</em>
              </p>
              <form className="form">
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 1)}
                >
                  <input
                    id="1"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label className="pre-text" htmlFor="1">
                    {strings[lang].nprAgentDidntCall}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 7)}
                >
                  <input
                    id="7"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label className="pre-text" htmlFor="7">
                    {strings[lang].nprNoAttemptToAddress}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 6)}
                >
                  <input
                    id="6"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label className="pre-text" htmlFor="6">
                    {strings[lang].nprWasNotAvailable}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 2)}
                >
                  <input
                    id="2"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label className="pre-text" htmlFor="2">
                    {strings[lang].doNotWantReturnProduct}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 3)}
                >
                  <input
                    id="3"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                  />
                  <label className="pre-text" htmlFor="3">
                    Others
                  </label>
                </div>
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button
                    className="button"
                    disabled={form === 0}
                    onClick={e => this.handleClick(e, 3)}
                  >
                    {strings[lang].next}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 ${
              screen === 3 ? 'show' : 'hide'
            } `}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].takeAction}{' '}
                  <span
                    className="icon-arrow_back"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.handleClick(e, 2)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              {this.getHeading(form)}
              <form className="form mrg-top-40">
                {this.getFields(form)}
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button className="button" onClick={this.handleSubmit}>
                    {strings[lang].submit}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      case 4:
        return (
          <Fragment>
            <div className={`col-md-16 col-xs-24 view-box noMinHeight pd-0`}>
              <div className="content">
                {this.renderNPRSuccessView(
                  this.state.customerFeed.issue_id ?? 0
                )}
              </div>
              <div
                className="footer text-left pos-rel"
                role="button"
                tabIndex="0"
                onClick={e => this.handleClick(e, 2)}
              >
                <a className="link">{strings[lang].youFacedAnyIssue}</a>
                <span className="icon-navigate_next pull-right" />
              </div>
            </div>
          </Fragment>
        );
      default:
    }
  }

  askForDelivery(e) {
    let { customerFeed, isRejected } = this.state;
    const { id } = e.target;
    if (id === 'yes') {
      customerFeed.is_rejected = isRejected = true;
    } else if (id === 'no') {
      customerFeed.is_rejected = isRejected = false;
    }
    this.setState({ customerFeed, isRejected });
  }

  alternateNumber(e) {
    const { tracking } = this.props;
    let { customerFeed, isAltNumber } = this.state;
    const { id } = e.target;
    if (id === 'no') {
      isAltNumber = true;
      customerFeed.phone_number = '';
    } else if (id === 'yes') {
      isAltNumber = false;
      customerFeed.phone_number = tracking.customer.phone;
    }
    this.setState({ customerFeed, isAltNumber });
  }

  dateToggle(e) {
    const { customerFeed } = this.state;
    customerFeed.preferred_date = e.target.id;
    this.setState({ customerFeed, preferred_date: null, isUserDate: true });
  }

  changeValue(e) {
    const { customerFeed } = this.state;
    customerFeed[e.target.id] = e.target.value;
    this.setState({ customerFeed });
  }

  searchLandmark(e) {
    const { customerFeed } = this.state;
    customerFeed[e.target.id] = e.target.value;
    this.setState({ customerFeed });
    if (e.target.value.length > 2) {
      this.props.fetchGoogleSearchHandler(e.target.value);
    }
  }

  selectedLandmark(e, landmark) {
    const { customerFeed } = this.state;
    customerFeed.landmark = landmark;
    this.setState({ customerFeed });
    this.props.resetGoogleSearchHandler();
  }

  handleSubmit(e) {
    e.preventDefault();
    const phonePattern = new RegExp(/^\d{10}$/);
    const datePattern = new RegExp(/[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/);
    const datePattern2 = new RegExp(/[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/);
    const pincodePattern = new RegExp(/^\d{6}$/);

    const { npr, tracking, user, page, location } = this.props;
    const { lang } = user;
    const { customerFeed, form, screen, isAltNumber } = this.state;
    let query = {};
    if (form === 5 || (form === 0 && npr.ops.issue_id === 5)) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !this.state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 5;
      query = (({ issue_id, comment, preferred_date }) => ({
        issue_id,
        comment,
        preferred_date
      }))(customerFeed);
    } else if (form === 7 || (form === 0 && npr.ops.issue_id === 7)) {
      if (
        // this.props.customerFeed?.capture?.address &&
        customerFeed.address === null ||
        customerFeed.address === '' ||
        customerFeed?.address?.trim().length < 10
      ) {
        return toast.error(strings[lang].addressErr);
      }
      if (
        // this.props.customerFeed?.capture?.drop_pincode &&
        !pincodePattern.test(customerFeed.drop_pincode) ||
        customerFeed.drop_pincode?.trim().length < 6
      ) {
        return toast.error(strings[lang].pincodeErr);
      }
      if (
        // this.props.customerFeed?.capture?.landmark &&
        customerFeed.landmark === null ||
        customerFeed.landmark === ''
      ) {
        return toast.error(strings[lang].landmarkErr);
      }
      if (
        // this.props.customerFeed?.capture?.landmark &&
        customerFeed?.landmark?.trim().length < 5
      ) {
        return toast.error(strings[lang].provideLandmark);
      }
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !this.state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 7;
      query = (({
        issue_id,
        address,
        drop_pincode,
        landmark,
        preferred_date
      }) => ({
        issue_id,
        address,
        drop_pincode,
        landmark,
        preferred_date
      }))(customerFeed);
    } else if (form === 6 || (form === 0 && npr.ops.issue_id === 6)) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !this.state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 6;
      query = (({ issue_id, preferred_date }) => ({
        issue_id,
        preferred_date
      }))(customerFeed);
    } else if (form === 1 || (form === 0 && npr.ops.issue_id === 1)) {
      if (
        isAltNumber === null
        // && this.props.customerFeed?.capture?.phone_number
      ) {
        return toast.error(strings[lang].chooseOption);
      }
      if (
        isAltNumber &&
        // this.props.customerFeed?.capture?.phone_number &&
        customerFeed.phone_number !== null &&
        customerFeed.phone_number !== ''
      ) {
        const firstChar = Number(customerFeed.phone_number.charAt(0));
        if (firstChar < 6) {
          return toast.error(strings[lang].phoneErr);
        }
      }
      if (
        isAltNumber &&
        // this.props.customerFeed?.capture?.phone_number &&
        !phonePattern.test(customerFeed.phone_number)
      ) {
        return toast.error(strings[lang].phoneErr);
      }
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !this.state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 1;
      query = (({ issue_id, phone_number, preferred_date }) => ({
        issue_id,
        phone_number,
        preferred_date
      }))(customerFeed);
    } else if (
      form === 3 ||
      (form === 0 && npr.ops.issue_id === 3) ||
      form === 4 ||
      (form === 0 && npr.ops.issue_id === 4)
    ) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !this.state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      if (npr.ops.issue_id) {
        customerFeed.issue_id = npr.ops.issue_id;
      } else {
        customerFeed.issue_id = 3;
      }
      query = (({ issue_id, comment, preferred_date }) => ({
        issue_id,
        comment,
        preferred_date
      }))(customerFeed);
    } else if (form === 2 || (form === 0 && npr.ops.issue_id === 2)) {
      if (this.state.isRejected === null) {
        return toast.error(strings[lang].chooseOption);
      }
      if (this.state.isRejected === false) {
        if (
          (!datePattern.test(customerFeed.preferred_date) &&
            !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate
        ) {
          return toast.error(strings[lang].dateErr);
        }
      }
      customerFeed.issue_id = 2;
      if (this.state.isRejected === true) {
        query = (({ issue_id, is_rejected, comment }) => ({
          issue_id,
          is_rejected,
          comment
        }))(customerFeed);
      } else {
        query = (({ issue_id, is_rejected, comment, preferred_date }) => ({
          issue_id,
          is_rejected,
          comment,
          preferred_date
        }))(customerFeed);
      }
    }
    if (screen === 1) {
      query.intent = '';
    } else {
      query.intent = nprClickEventDescription(query.issue_id);
    }
    if (page.source) {
      query.feedback_source = page.source;
    }
    if (query.preferred_date) {
      query.preferred_date = moment(query.preferred_date).format('YYYY-MM-DD');
    }
    // Passing security_key value if the enterprise enabled security check.
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    query.security_key =
      searchParams.get('security_key') || searchParams.get('key');

    this.setState({ isSubmit: true });
    const toastId = toast.loading(strings[lang].actionInProgress);
    postNprConcernHandler(
      tracking.trackingId,
      tracking.config.hasRaisedIssue,
      query
    ).then(res => {
      if (res.meta.success) {
        toast.success(strings[lang].submittedConcern(tracking.courier.name), {
          id: toastId
        });
        this.setState({ isSubmit: false, screen: 4 });
        // this.goBack();
      } else {
        this.setState({ isSubmit: false });
        toast.error(res.meta.message, { id: toastId });
      }
    });
  }

  render() {
    const { tracking, user } = this.props;
    const { lang, routePrefix } = user;
    const { screen } = this.state;
    return !tracking.isFetching ? (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 text-left main-container
        ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
        ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
        ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
        ${
          user.subdomain == '1mg' || user.subdomain == '1mg-test'
            ? 'onemg'
            : user.subdomain
        }`}
      >
        <div className="row col-gutter-130 xs-pd-left-10 xs-pd-right-10">
          <div className="col-md-8 pd-left-20  pd-right-20 xs-pd-0 col-xs-24">
            <div className="bg-white hidden-xs mrg-btm-20">
              <div className="ndr order-info pd-20">
                <div className="header">
                  <img
                    className="mrg-right-10 xs-display-in-block"
                    src="/images/order-icon.svg"
                    alt="order icon"
                  />
                  <span>
                    {['rblbank', 'rblbank-test'].includes(user.subdomain)
                      ? 'Shipment Details'
                      : strings[lang].orderDetails}
                  </span>
                </div>
                <div className="detailed">
                  <p className="mrg-top-10">
                    <label>{strings[lang].courier}: &nbsp;</label>
                    <span className="highlight">{tracking.courier.name}</span>
                  </p>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15 xs-mrg-btm-5">
                      <label>{strings[lang].trackingId}: &nbsp;</label>
                      <span className="text user-select">
                        {tracking.waybill}
                      </span>
                    </div>
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15 mrg-top-10">
                      <label className="pull-left ss">
                        {strings[lang].orderId}: &nbsp;
                      </label>
                      <span className="text user-select">
                        {tracking.orderId ? tracking.orderId : 'NA'}
                      </span>
                    </div>
                  </div>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15">
                      <p>
                        <span className="rectangle" />
                        <label className="vivid-orange pull-left">
                          {strings[lang].status}: &nbsp;
                        </label>
                        <span className="text user-select">
                          <strong
                            style={{ color: tracking.order.timelineColor }}
                          >
                            {strings[lang].statusOnDateFn(
                              tracking.latestStatus.text,
                              moment(
                                tracking.latestStatus.timestamp.slice(0, 10)
                              ).format('Do MMM YY')
                            )}
                          </strong>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="track-order xs-mrg-btm-10">
              <NavLink
                to={`${routePrefix ? `/${routePrefix}` : ''}/reverse${
                  this.props.history.location.search
                }`}
              >
                {strings[lang].viewTracking}
              </NavLink>
            </div>
          </div>

          {!this.state.isSubmit && this.renderScreen(screen)}
        </div>
      </div>
    ) : null;
  }
}

Issue.contextTypes = {
  router: PropTypes.object.isRequired
};

Issue.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  location: PropTypes.object,
  customerFeed: PropTypes.object,
  opsFeed: PropTypes.object,
  npr: PropTypes.object,
  history: PropTypes.object,
  googleSearch: PropTypes.object,
  fetchGoogleSearchHandler: PropTypes.func,
  resetGoogleSearchHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  opsFeed: state.opsFeed,
  customerFeed: state.customerFeed,
  user: state.user,
  page: state.page,
  googleSearch: state.googleSearch,
  npr: state.npr
});

const mapDispatchToProps = dispatch => ({
  fetchGoogleSearchHandler: keyword => dispatch(googleSearchHandler(keyword)),
  resetGoogleSearchHandler: () => dispatch(resetGoogleSearchHandler())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Issue));
